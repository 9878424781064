import { useEffect, useRef } from 'react'

export default function SongkickTourbox() {
	const ref = useRef(null)

	// ! Such shenanigans!
	// They are used here because the songkick tourbox widget removes the a element
	// which confuses react and ends up triggering an error on dismount (the element doesn't
	//	exist anymore).
	// To avoid that, we give react an always available element, and mount whatever we need on the go
	// inside said element directly.
	useEffect(() => {
		const fragment = document.createDocumentFragment()
		const script = document.createElement('script')
		const link = document.createElement('a')

		link.setAttribute('href', 'https://www.songkick.com/artists/9030044')
		link.setAttribute('class', 'songkick-widget')
		link.setAttribute('data-theme', 'light')
		link.setAttribute('data-track-button', 'on')
		link.setAttribute('data-detect-style', 'true')
		link.setAttribute('data-background-color', 'transparent')
		link.textContent = 'Mobius (FR) tour dates'

		script.src = '//widget.songkick.com/9030044/widget.js'

		fragment.appendChild(link)
		fragment.appendChild(script)

		ref.current.appendChild(fragment)
	}, [])

	return (
		<div
			ref={ref}
			style={{
				fontSize: '.8em',
			}}
		></div>
	)
}
