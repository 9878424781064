import { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import Logo from '../images/full-logo-black.png'
import { useWindowScroll } from 'react-use'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Link } from '../components/Typography'

const MobiusLogo = styled.img`
	width: 200px;
	cursor: pointer;
`

const NavigationContainer = styled.section`
	position: fixed;
	display: flex;

	padding: 0 20px;
	background-color: rgba(248, 249, 243, 0.4);

	height: 80px;
	@media (min-width: 960px) {
		align-items: center;
		justify-content: space-between;
	}

	@media (max-width: 960px) {
		flex-direction: column;
	}

	${(props) => {
		if (props.elevate) {
			return css`
				background-color: rgb(248, 249, 243);
				box-shadow: 0 0px 10px 0px rgb(0, 0, 0);
			`
		}
	}}

	transition: box-shadow 0.2s ease-in-out;
	width: 100vw;
	z-index: 100;

	${(props) => {
		if (props.burgerMenuOpen) {
			return css`
				height: 100vh;
				background: white;
				overflow: auto;
			`
		}
	}}
`

const LeftPartContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	position: relative;
`

const MenuContainer = styled.ul`
	top: 0;
	right: 0;

	display: flex;
	font-size: 0.9em;
	@media (max-width: 960px) {
		margin: 0;
		padding: 0;

		height: 80%;

		flex-direction: column;
		justify-content: space-around;

		${(props) => {
			if (!props.burgerMenuOpen) {
				return css`
					display: none;
				`
			}
		}};
	}
`

const MenuEntry = styled.li`
	list-style: none;
	text-transform: uppercase;
	margin: 10px;

	@media (max-width: 960px) {
		align-self: center;
	}
`

const MenuIcon = styled(FontAwesomeIcon)`
	cursor: pointer;
	position: absolute;
	left: 0px;
	top: 25px;

	@media (min-width: 960px) {
		display: none;
	}
`

export default function Navigation() {
	const { y } = useWindowScroll()
	const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)

	const closeBurgerMenu = useCallback(() => setBurgerMenuOpen(false), [
		setBurgerMenuOpen,
	])

	return (
		<NavigationContainer elevate={y > 0} burgerMenuOpen={burgerMenuOpen}>
			<LeftPartContainer id="left-part-container">
				<MenuIcon
					icon={faBars}
					onClick={() => {
						setBurgerMenuOpen(!burgerMenuOpen)
					}}
				/>
				<MobiusLogo
					alt="logo"
					src={Logo}
					onClick={() => {
						setBurgerMenuOpen(false)
						window.scrollTo({ top: 0 })
					}}
				/>
			</LeftPartContainer>
			<MenuContainer burgerMenuOpen={burgerMenuOpen}>
				<MenuEntry>
					<Link
						href="https://mobiusofficial.bandcamp.com/merch"
						target="_blank"
						rel="noopener noreferrer"
						onClick={closeBurgerMenu}
					>
						Merch
					</Link>
				</MenuEntry>
				<MenuEntry>
					<Link href="#releases" onClick={closeBurgerMenu}>
						Releases
					</Link>
				</MenuEntry>
				<MenuEntry>
					<Link href="#videos" onClick={closeBurgerMenu}>
						Videos
					</Link>
				</MenuEntry>
				<MenuEntry>
					<Link href="#band" onClick={closeBurgerMenu}>
						Bio
					</Link>
				</MenuEntry>
				<MenuEntry>
					<Link href="#press" onClick={closeBurgerMenu}>
						Press
					</Link>
				</MenuEntry>
				<MenuEntry>
					<Link
						href="https://drive.google.com/file/d/1K_AY5v6LtJN_m2dHdbCT5n1CA5mifwNW/view?usp=sharing"
						target="_blank"
						rel="noopener noreferrer"
						onClick={closeBurgerMenu}
					>
						EPK
					</Link>
				</MenuEntry>
				<MenuEntry>
					<Link href="#tour" onClick={closeBurgerMenu}>
						Tour
					</Link>
				</MenuEntry>
				<MenuEntry>
					<Link href="#contact" onClick={closeBurgerMenu}>
						Contact
					</Link>
				</MenuEntry>
			</MenuContainer>
		</NavigationContainer>
	)
}
