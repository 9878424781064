import styled from 'styled-components'

const EmailInput = styled.input`
	display: inline-block;
	height: 50px;

	padding: 5px;
	border: solid 1px black;
	font-size: 0.8em;
`
const SubmitButton = styled.input`
	cursor: pointer;
	border: black solid 1px;
	background: transparent;
	font-weight: bold;
	font-size: 0.8em;

	&:hover {
		box-shadow: 0 2px 5px 1px black;
	}
	transition: all 0.2s ease-in;
	@media (max-width: 700px) {
		height: 50px;
	}
`

const Form = styled.form`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-bottom: 20px;

	@media (max-width: 700px) {
		flex-direction: column;
	}
`

export default function MailchimpForm() {
	return (
		<Form
			action="https://bandcamp.us4.list-manage.com/subscribe/post?u=ad85b9433caaa0527b23615be&amp;id=2ab08fb892"
			method="post"
			id="mc-embedded-subscribe-form"
			name="mc-embedded-subscribe-form"
			target="_blank"
			noValidate
		>
			<EmailInput
				type="email"
				defaultValue=""
				name="EMAIL"
				className="email"
				id="mce-EMAIL"
				placeholder="Email address"
				required
			/>
			<input
				style={{ position: 'absolute', left: -5000 }}
				type="text"
				name="b_ad85b9433caaa0527b23615be_2ab08fb892"
				tabIndex={-1}
				value=""
			/>
			<SubmitButton
				type="submit"
				value="Subscribe to the newsletter"
				name="subscribe"
				id="mc-embedded-subscribe"
				className="button"
			/>
		</Form>
	)
}
