import styled from 'styled-components'

const AlbumContainer = styled.section`
	@media (min-width: 900px) {
		display: flex;
		justify-content: space-between;
	}
	min-height: 500px;
`

const ArtworkHolder = styled.img`
	@media (min-width: 900px) {
		height: 500px;
		margin-right: 50px;
	}

	@media (max-width: 900px) {
		width: 100%;
	}

	box-shadow: 0 1px 5px 1px black;
`

const AlbumDetailsContainer = styled.div`
	width: 100%;
`

const AlbumTitle = styled.h2``

const AlbumPlayer = styled.iframe`
	box-shadow: 0 1px 5px 1px black;
`

const AlbumPresentationContainer = styled.div`
	&:nth-child(2n + 1) {
		margin-top: 20px;
	}
`

export default function AlbumPresentation({
	img: { src, alt },
	spotify: { url, title },
	albumTitle,
}) {
	return (
		<AlbumPresentationContainer>
			<AlbumTitle className="album-title">{albumTitle}</AlbumTitle>

			<AlbumContainer className="album-container">
				<ArtworkHolder src={src} alt={alt} className="artwork-holder" />
				<AlbumDetailsContainer className="album-details-container">
					<AlbumPlayer
						title={title}
						src={url}
						width="100%"
						height="380"
						frameBorder="0"
						allowTransparency={true}
						allow="encrypted-media"
					/>
				</AlbumDetailsContainer>
			</AlbumContainer>
		</AlbumPresentationContainer>
	)
}
