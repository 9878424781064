import styled from 'styled-components'

import { SectionTitle, Paragraph } from '../../components/Typography'

const pressQuotesByAlbum = [
	{
		album: 'KALA',
		quotes: [
			{
				mediaName: 'Metal Hammer',
				author: 'Sophie Maughan',
				note: {
					mark: 4,
					over: 5,
				},
				content: `KALA will whet appetites for intelligent prog and steal your breath away.`,
			},
			{
				mediaName: 'Distorted sound',
				author: 'James Weaver',
				note: {
					mark: 4,
					over: 5,
				},
				content: `A multifaceted and dynamic listen, rich in character and adventurous. Brave, explorative and an utter thrill to consume.`,
			},
			{
				mediaName: 'PROGSPACE',
				author: 'Rune B. Reinas',
				note: {
					mark: 4,
					over: 5,
				},
				content: `KALA is a fantastic sophomore full-length. It's filled with surprising twists and turns, innovative songwritting and a refreshing take on prog metal and djent.`,
			},
			{
				mediaName: 'Rock Hard',
				author: 'Stéphane Auzilleau',
				note: {
					mark: 4,
					over: 5,
				},
				content: `Here is a band which progresses at high speed. Rare are the bands who can master this mix between world and metal music. Among Cynic, Aghora, Orphaned Land and Myrath, Mobius is one of them.`,
			},
			{
				mediaName: 'Music Waves',
				author: 'Luc Millesime',
				note: {
					mark: 4,
					over: 5,
				},
				content: `One of the most original and interesting albums of this year.`,
			},
			{
				mediaName: 'Metal Injection',
				author: 'Jordan Blum',
				content: `Jazzy piano, djent devastation, operatic vocals, hypnotic rhythmic density, Middle Eastern accentuation, a stunning sophomore effort.`,
			},
			{
				mediaName: 'Metallian',
				author: 'Denis Halleux',
				content: `A new musicality, a new way to enjoy progressive metal. A stunning maestria.`,
			},
			{
				mediaName: 'Prog Mag',
				author: 'Matt Mills',
				content: `No mistaking the sound of a band with lofty creative goals.`,
			},
			{
				mediaName: 'Devolution Mag',
				author: 'Jo Wright',
				content: `The time, thought and care that must have gone into this record is humbling. What Mobius gives is not simply a new album, but a new dimension of music. Its sound will touch your soul.`,
			},
		],
	},
	{
		album: 'The Line',
		quotes: [
			{
				mediaName: 'Metallian',
				content: `A debut album, remarkable in every aspect. Mobius combines modernism and real melodic work for a more than qualitative result. Progressive and interesting metal lovers should definitely be attentive to this band.`,
			},
			{
				mediaName: 'World Metal Domination',
				content: `Mobius is the next big thing in modern progressive metal.`,
			},
			{
				mediaName: 'French Metal',
				content: `Syncopated rhythmics, jazz harmonies and symphonic arrangements, vocals are sometimes rock sometimes lyrical with world music influences.`,
			},
			{
				mediaName: 'Metalheads.de',
				content: `A real music storm. Artistic solos and hectic rhythms.`,
			},
			{
				mediaName: 'TürkGitar',
				content: `An album and a composition which show that Mobius is a promising band.`,
			},
		],
	},
]

const PressQuoteColumnsContainer = styled.section`
	@media (min-width: 1000px) {
		display: flex;
		justify-content: space-between;
	}
	flex-wrap: wrap;
`

const PressQuoteContainer = styled.div`
	width: 45%;

	@media (max-width: 1000px) {
		width: 100%;
	}
`

const QuoteMediaName = styled.h3`
	text-transform: uppercase;
	margin-top: 20px;
	margin-bottom: 0;
	font-size: 1em;
`

const QuoteAuthor = styled.em`
	font-size: 0.8em;
`

const QuoteNote = styled.em`
	font-size: 0.8em;
	margin-left: 10px;
`

const QuoteContent = styled(Paragraph)`
	margin: 0;
`

const AlbumTitle = styled.h2`
	margin-top: 20px;
	margin: 0px auto;

	margin-top: ${(props) => (props.addMarginTop ? '40px' : 0)};
	font-family: Calibri Light;
`

export default function Press() {
	return (
		<>
			<SectionTitle>Press</SectionTitle>

			{pressQuotesByAlbum.map(({ album, quotes }, albumIndex) => {
				return (
					<>
						<AlbumTitle addMarginTop={albumIndex > 0}>
							{album}
						</AlbumTitle>
						<PressQuoteColumnsContainer>
							{quotes.map((quote, quoteIndex) => (
								<PressQuoteContainer key={quoteIndex}>
									<QuoteMediaName>
										{quote.mediaName}
									</QuoteMediaName>
									{quote.author && (
										<QuoteAuthor>
											{quote.author}
										</QuoteAuthor>
									)}
									{quote.note && (
										<QuoteNote>
											{quote.note.mark}/{quote.note.over}
										</QuoteNote>
									)}
									<QuoteContent>{quote.content}</QuoteContent>
								</PressQuoteContainer>
							))}
						</PressQuoteColumnsContainer>
					</>
				)
			})}
		</>
	)
}
