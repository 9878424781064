import ArtworkKala from './images/artwork-kala.jpg'
import ArtworkTheLine from './images/artwork-the-line.jpg'

import AlbumPresentation from './AlbumPresentation'
import { SectionTitle } from '../../components/Typography'

export default function Music() {
	return (
		<>
			<SectionTitle>Releases</SectionTitle>
			<AlbumPresentation
				key="KALA"
				albumTitle={'KALA - 2020'}
				img={{ alt: 'KALA artwork', src: ArtworkKala }}
				spotify={{
					title: 'KALA - 2020',
					url:
						'https://open.spotify.com/embed/album/3WX3hxZlc6JpXdGUr1G3P7',
				}}
			/>
			<AlbumPresentation
				key="the-line"
				albumTitle={'The Line - 2016'}
				img={{ alt: 'The Line artwork', src: ArtworkTheLine }}
				spotify={{
					title: 'The Line - 2016',
					url:
						'https://open.spotify.com/embed/album/6NJvb0UJfgMhaklKYwsVhD',
				}}
			/>
		</>
	)
}
