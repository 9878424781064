import styled from 'styled-components'

// ! Youtube resize tip:
// https://coolestguidesontheplanet.com/videodrome/youtube/

const Iframe = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`

const IframeContainer = styled.div`
	height: 0;
	padding-top: 30px;
	position: relative;
	padding-bottom: 56.25%;

	margin-top: 10px;

	box-shadow: 0 2px 5px 1px black;

	& object,
	& embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`

export default function YoutubePlayer({ src, className = '' }) {
	return (
		<>
			<IframeContainer>
				<Iframe
					src={src}
					className={className}
					frameBorder="0"
					allowFullScreen
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				/>
			</IframeContainer>
		</>
	)
}
