import styled from 'styled-components'

import {
	faFacebookSquare,
	faInstagramSquare,
	faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons'
import { faMusic } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import MailChimpForm from './MailChimpForm'

const Link = styled.a`
	cursor: pointer;
	color: black;
	text-decoration: none;
	font-weight: bold;
	font-size: 1.3em;
	margin: 15px;

	@media (min-width: 700px) {
		margin: 40px;
	}
`

const FooterContainer = styled.section`
	width: 100%;
`

const LinksContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
`

export default function Footer() {
	return (
		<FooterContainer className="footer-container">
			<MailChimpForm />
			<LinksContainer>
				<Link
					href="https://www.facebook.com/mobiusofficialband"
					rel="noopener noreferrer"
					target="_blank"
				>
					<FontAwesomeIcon icon={faFacebookSquare} />
				</Link>
				<Link
					href="https://www.instagram.com/mobiusofficialband/"
					rel="noopener noreferrer"
					target="_blank"
				>
					<FontAwesomeIcon icon={faInstagramSquare} />
				</Link>
				<Link
					href="https://www.youtube.com/user/TheMobiusTV"
					rel="noopener noreferrer"
					target="_blank"
				>
					<FontAwesomeIcon icon={faYoutubeSquare} />
				</Link>
				<Link
					href="http://smarturl.it/mobius-band"
					rel="noopener noreferrer"
					target="_blank"
				>
					<FontAwesomeIcon icon={faMusic} />
				</Link>
			</LinksContainer>
		</FooterContainer>
	)
}
