import { SectionTitle } from '../../components/Typography'
import YoutubePlayer from '../../components/YoutubePlayer'
import styled from 'styled-components'

const VideoContainer = styled.div`
	width: 49%;
	padding: 0;
	margin: 0;

	@media (max-width: 960px) {
		width: 100%;
	}
`

const PageContainer = styled.section`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`

export default function Videos() {
	return (
		<>
			<SectionTitle id="videos">Videos</SectionTitle>
			<PageContainer>
				{[
					// Bhati
					'https://www.youtube.com/embed/_PCoy5EV57A',
					// Akasha
					'https://www.youtube.com/embed/0QALLpwCLrQ',
					// Abhinivesha
					'https://www.youtube.com/embed/6Qz_eIwsOsY',
					// Mukti
					'https://www.youtube.com/embed/LmctkAYLELY',
					// Gogo Fresco
					'https://www.youtube.com/embed/VBcUmv3j8AU',
					// Maloyassenger
					'https://www.youtube.com/embed/-kHWrcDL4B8',
				].map((url) => (
					<VideoContainer>
						<YoutubePlayer src={url} />
					</VideoContainer>
				))}
			</PageContainer>
		</>
	)
}
