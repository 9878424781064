import styled from 'styled-components'

import Band from './pages/Band'
import Press from './pages/Press'
import Home from './pages/Home'
import Music from './pages/Music'

import Footer from './components/Footer'
import Navigation from './components/Navigation'
import Tour from './pages/Tour'
import Videos from './pages/Videos'
import Contact from './pages/Contact'

const FullScreenSection = styled.section`
	height: 100vh;
	width: 100vw;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	box-shadow: inset 0 -50px 50px 20px rgb(248, 249, 243);
	position: relative;
`

const NormalSection = styled.section`
	display: flex;
	justify-content: center;
	flex-direction: column;

	max-width: 960px;
	padding: 0 20px;
	margin: auto;
`

const PageContainer = styled.div`
	position: relative;
`

export default function App() {
	return (
		<PageContainer>
			<Navigation />
			<FullScreenSection id="home">
				<Home />
			</FullScreenSection>
			<NormalSection id="releases">
				<Music />
			</NormalSection>
			<NormalSection id="videos">
				<Videos />
			</NormalSection>
			<NormalSection id="band">
				<Band />
			</NormalSection>
			<NormalSection id="press">
				<Press />
			</NormalSection>
			<NormalSection id="tour">
				<Tour />
			</NormalSection>
			<NormalSection id="contact">
				<Contact />
			</NormalSection>
			<NormalSection>
				<Footer />
			</NormalSection>
		</PageContainer>
	)
}
