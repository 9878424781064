import styled from 'styled-components'
import FullWidthImage from '../../components/FullWidthImage'
import { Paragraph, SectionTitle } from '../../components/Typography'
import FullBandImage from './images/full-band.jpg'

const BandPicture = styled(FullWidthImage)`
	margin-bottom: 50px;
`

const BioContainer = styled.section`
	@media (min-width: 1000px) {
		display: flex;
	}
`
const BioColumn = styled.div`
	width: 100%;

	&:nth-child(1) {
		margin-right: 40px;
	}
`

export default function Biography() {
	return (
		<>
			<SectionTitle>Bio</SectionTitle>

			<BandPicture src={FullBandImage} />
			<BioContainer>
				<BioColumn>
					<Paragraph>
						Mobius takes its roots on the Reunion island, with
						Adrien Brunet (drums), Guillaume Deveaux (keyboards),
						and Anton Laravine (guitar). The three teenagers jam
						together with a jazz style and cover the songs of their
						favourite bands: Dream Theater, Nightwish, SymphonyX.
						From the beginning, the members of Mobius have enjoyed
						the technical challenge of music.
					</Paragraph>
					<Paragraph>
						Their music, inspired by the Reunion Island where the
						band takes its roots from, is an energetic bend of
						modern metal, indian and asian tones, mixed with Maloya
						and jazz.
					</Paragraph>
					<Paragraph>
						With the debut album "The Line" (2016), Mobius shared
						the stage with acts such as Leprous, Car Bomb, Hypno5e
						and many more.
					</Paragraph>
				</BioColumn>
				<BioColumn>
					<Paragraph>
						Anton and Julien leave the band, and the 3 members are
						left looking for a new guitar and bass player. Xavier
						Pompon on guitars and Alexandre Gaudencio, guitarist who
						takes the bass part for the occasion, integrate the band
						with the will to create a new album and to spread their
						music.
					</Paragraph>
					<Paragraph>
						In January 2020, "KALA" was released. This second opus,
						with djenty riffs, epic synths, world and ethnic voices
						and an eccentric polyrhythmic backing intends to
						transport the listener to a rich, intoxicating landscape
						filled with intricately-woven melodies rife with colour
						and detail.
					</Paragraph>
					<Paragraph>
						In this Opus, the band explores new techniques and
						cultures with Konnakol and Sanskrit.
					</Paragraph>
					<Paragraph>
						With this sophomore album, Mobius played festivals such
						as the Euroblast, Ready for prog, Les Lunatiques and
						others.
					</Paragraph>
				</BioColumn>
			</BioContainer>
		</>
	)
}
