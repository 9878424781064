import SongkickTourbox from '../../components/SongKickTourbox'
import { SectionTitle } from '../../components/Typography'

export default function Tour() {
	return (
		<>
			<SectionTitle>Tour</SectionTitle>
			<SongkickTourbox />
		</>
	)
}
