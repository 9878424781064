import {
	SectionTitle,
	Paragraph,
	ContentTitle,
} from '../../components/Typography'
import styled from 'styled-components'
import OverloadLogo from '../../images/overload.png'
import GorillaLogo from '../../images/gorilla.png'
import { Link } from '../../components/Typography'

const LogoContainer = styled.img`
	width: 150px;
`

// Keeping this to display partners in columns in case it's interesting
const Partners = styled.section`
	/* display: flex; */
	/* justify-content: space-around; */
`

const Partner = styled.section`
	/* display: flex;
	flex-direction: column; */
`

export default function Music() {
	return (
		<>
			<SectionTitle>Contacts</SectionTitle>

			<ContentTitle>Contact the band</ContentTitle>
			<Paragraph style={{ margin: 0 }}>contact@mobius-band.com</Paragraph>

			<Partners>
				<Partner>
					<ContentTitle>Overload Guitars</ContentTitle>
					<Link
						href="http://www.overloadguitars.com/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<LogoContainer src={OverloadLogo} />
					</Link>
					<Paragraph style={{ margin: 0 }}>
						info@overloadguitars.com
					</Paragraph>
					<Paragraph style={{ margin: 0 }}>
						info@overloaddrums.com
					</Paragraph>
				</Partner>

				<Partner>
					<ContentTitle>Gorilla Pickups</ContentTitle>
					<Link
						href="https://www.gorillapickups.com/home"
						target="_blank"
						rel="noopener noreferrer"
					>
						<LogoContainer src={GorillaLogo} />
					</Link>
					<Paragraph style={{ marginTop: 10 }}>
						info@overloadguitars.com
					</Paragraph>
				</Partner>
			</Partners>
		</>
	)
}
